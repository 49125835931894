<template>
  <div>
    <div class="content-header">
      <header class="header"></header>
    </div>

    <div class="logo">
      <img src="../assets/img/logo.png" />
      <p class="logo-title">中愈智谷</p>
    </div>
    <section class="layou">
      <div class="merchant">
        <span class="span-title">商家名称：</span>
        <span>{{ name }}</span>
      </div>
      
      <div class="input-section">
        <div class="section-hd">支付金额：</div>
        <div class="section-bd">
          <div class="input-group">
            ￥
            <input v-model="money" class="input" name="money" type="digit" @input="search" placeholder="0.00" />
          </div>
        </div>
      </div>

      <!-- <div class="merchant">
        <span class="span-title">支付金额</span>
        <span>
          <input type="text" v-model="money" placeholder="请输入支付金额" @input="search" />
        </span>
      </div> -->
      <div class="merchant">
        <span class="span-title">ATU抵扣</span>
        <!-- 单选框 -->
        <input type="radio" name="radios" value="1" :checked="radios==1" @click="radiosClick(1)"><label style="padding:0 40px 0 5px;">是</label>
        <input type="radio" name="radios" value="0" :checked="radios==0" @click="radiosClick(0)"><label style="padding:0 20px 0 5px;">否</label>
      </div>
      <div style="font-size: 13px;margin: 15px 0 0 5px;color: #000;">
        ATU可1:1抵扣支付款{{this.virtual_bai}}%的部分,当前剩余：<span style="color: red">{{this.virtual_yu}}</span>,已抵扣:<span style="color: red">{{this.deduction}}</span>
      </div>
      <div v-show="paybtn" class="btn" @click="pay">
        <button>立即支付:{{this.price}}元</button>
      </div>
      <div id="demo">
        
      </div>
      
    </section>
  </div>
</template>

<script>
//  2存在 1 不存在

import { getUrlKey } from "@/utils/utils";
import axios from "axios";

export default {
  name: "Home",

  created() {
    var code = getUrlKey("code", window.location.href);
    var state = getUrlKey("state", window.location.href);

    this.name = state.split(",")[0];
    this.shop_phone = state.split(",")[1];

    var url = "https://www.zhongyuzhigu.com/api/user/getCashier?code="+code+"&spreadPhone=" + this.shop_phone;
    axios.get(url).then(
      (response) => {
        if (response.data.status == 200) {
          this.openid = response.data.data.openid;
          console.log(this.openid)
          this.uid = response.data.data.uid;
          this.virtual = this.virtual_yu = response.data.data.virtual;
          this.virtual_bi = response.data.data.virtual_bi;
          this.virtual_bai = response.data.data.virtual_bai;
          this.deduction = this.money*response.data.data.virtual_bi ?? 0;
          if(this.virtual <= 0){
            this.radios = 0;
          }
        } else {
          alert(response.data.msg);
        }
        console.log(this.openid)
      },
      (response) => {
        alert("系统异常");
      }
    );
  },
  data() {
    return {
      money: '',
      price: '0.00',
      uid:0,
      virtual: '0.00',
      virtual_yu: '0.00',
      virtual_bi: '0.00',
      virtual_bai:'0.00',
      deduction:'0.00',
      openid: "",
      name: "",
      shop_phone: "",
      radios:1,
      paybtn: true,
    };
  },
  methods: {
    radiosClick(type){
      this.radios = type;
      if(type == 0){
        this.deduction = '0.00';
        this.virtual_yu = this.virtual;
        this.price = this.money ? this.money: '0.00';
      }else{
        this.search()
        this.deduction = this.money*this.virtual_bi ?? '0.00';
        this.virtual_yu = this.virtual-this.deduction ?? '0.00';
        this.price = this.money - this.deduction ?? '0.00';
        this.deduction = this.deduction.toFixed(2)
        this.virtual_yu = this.virtual_yu.toFixed(2)
        this.price = this.price.toFixed(2)
      }
    },
    search(){
      if(this.radios){
        if(this.virtual-this.money*this.virtual_bi<=0){
          this.money = '';
          this.deduction = '0.00';
          this.virtual_yu = this.virtual;
          this.price = 0;
          alert("ATU不足,请选择否")
          return
        }
        this.deduction = this.money*this.virtual_bi ?? '0.00';
        this.virtual_yu = this.virtual-this.deduction ?? '0.00';
        this.price = this.money - this.deduction ?? '0.00';
        this.deduction = this.deduction.toFixed(2)
        this.price = this.price.toFixed(2)
        this.virtual_yu = this.virtual_yu.toFixed(2)
      }else{
        this.price = this.money;
      }
    },
    pay() {
      let that = this;

      if (this.money == "") {
        alert("金额不能为空");
        return;
      }

      that.paybtn = false;
      var url = "https://www.zhongyuzhigu.com/api/user/payPoly";
      axios
        .post(url, {
          pay_money: this.price,
          openid: this.openid,
          shop_phone: this.shop_phone,
          money: this.money,
          type: this.radios,
          deduction: this.deduction,
          uid:this.uid,
          mark: "wxpay",
        })
        .then(
          (response) => {
            if (response.data.status == 200) {
              console.log(response.data)
              window.location.href = response.data.msg
            } else {
              alert(JSON.stringify(response.data.msg));
              that.paybtn = true;
            }
          },
          (response) => {
            alert("系统异常");
            that.paybtn = true;
          }
        );
    },
  },
};
</script>
<style lang="scss" scoped>

.input-section {
    background-color: #fff;
    margin-bottom: 12px;
		.section-hd {
			padding: 15px;
			font-size: 16px;
			color: #333333;
		}

		.section-bd {
			padding-right: 15px;
			padding-left: 15px;
		}

		.input-group {
			display: flex;
			align-items: flex-end;
			padding: 22px 10px 24px;
			font-size: 40px;
			color: #999999;
		}

		.input {
			flex: 1;
			height: 55px;
			margin-left: 7px;
			font-size:50px;
			color: #282828;
		}

		.discount {
			padding: 9px 10px;
			border-top: 1px solid #eeeeee;
			font-size: 14px;
			color: #e93323;
		}
	}


.content-header {
  position: relative;
  height: 220px;
  background: #f7f7f7;
  font-family: Adobe Heiti Std;
}
.header {
  height: 110px;
  font-size: 18px;
  padding-top: 4px;
  font-weight: normal;
  color: #ffffff;
  line-height: 20px;
  text-shadow: 0px 0px 0px rgba(31, 26, 4, 0.35);
  text-align: center;
  background: #fdca00;
}
.logo {
  position: absolute;
  top: 40px;
  left: 50%;
  margin-left: -2.185rem;
  width: 4.37rem;
  height: 130px;
  img {
    width: 4.37rem;
  }
  .logo-title {
    margin-top: 7px;
    font-size: 16px;
    font-weight: normal;
    color: #666666;
    // line-height: 29px;
    text-align: center;
  }
}
.layou {
  height: calc(100vh - 276px);
  background: #f7f7f7;
  .merchant {
    height: 43px;
    line-height: 43px;
    padding-left: 0.58rem;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: normal;
    color: #333333;
    background: #fff;
    .span-title {
      display: inline-block;
      width: 4.2rem;
      height: 100%;
    }
    input {
      font-size: 0.68rem;
      font-weight: normal;
      color: #cbcbcb;
    }
    input::-webkit-input-placeholder {
      font-size: 16px;
      font-family: Adobe Heiti Std;
      font-weight: normal;
      color: #cbcbcb;
    }
    input:-moz-input-placeholder {
      font-size: 16px;
      font-weight: normal;
      color: #cbcbcb;
    }
    input::-moz-input-placeholder,
    input::-ms-input-placeholder {
      font-size: 0.68rem;
      font-weight: normal;
      color: red;
    }
  }
  .btn {
    position: fixed;
    bottom: 33px;
    width: 100%;
    height: 1.89rem;
    text-align: center;
    button {
      margin: 0 auto;
      width: 14.72rem;
      height: 1.89rem;
      font-size: 18px;
      text-shadow: 0px 0px 0px rgba(31, 26, 4, 0.07);
      font-weight: normal;
      font-family: Adobe Heiti Std;
      color: #ffffff;
      background: #fdca00;
      border-radius: 5px;
    }
  }
}
</style>
